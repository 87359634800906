import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next"
import { withTrans }  from '../../sections/i18next_setting/withTrans'
import imgLB from "../../assets/image/logo_noksoft.png";
import imgLW from "../../assets/image/logo_noksoft.png";
import icon_email from "../../assets/image/home-2/png/contact_mail.png";
import icon_phone from "../../assets/image/home-2/png/contact_phone.png";

const Footer = ({ className, ...rest }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={`bg-default-1 pb-13 pb-lg-10 ${className}`} {...rest}>
        <div className="container">
          <hr className="border-top border-default-color-2 m-0 p-0" />
          <div className="row pt-12 pt-lg-25 justify-content-center">
            <div className="col-lg-4 col-md-5 col-md-3">
              <div className="pr-xl-20 mb-11 mb-lg-0">
                <div className="brand-logo mb-7 mb-lg-8">
                  <Link to="/">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      className="dark-version-logo mx-auto mx-0"
                      src={imgLW}
                      alt=""
                    />
                  </Link>
                </div>
                <h4> {t("Address")} </h4>

                <p className="font-size-5 line-height-28 mb-0">
                {t("Address_Noksoft")}
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 offset-lg-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-12 col-xs-12">
                  <div className="media align-items-center mb-12">
                    <div className="border square-83 rounded-10 mr-9">
                      <img src={icon_email} alt="" />
                    </div>
                    <div className="content">
                      <h5> {t("Email")} </h5>
                      <p className="font-size-5 line-height-28 mb-0">
                        info@noksoft.co.th
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-xs-12">
                  <div className="media align-items-center mb-12">
                    <div className="border square-83 rounded-10 mr-9">
                      <img src={icon_phone} alt="" />
                    </div>
                    <div className="content">
                      <h5> {t("Phone")} </h5>
                      <p className="font-size-5 line-height-28 mb-0">
                      02-1078144
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-3 pt-lg-22">
            <div className="col-md-6 text-center text-md-left">
              <div className="copyright mb-5">
                <p className="mb-0 font-size-3 text-stone">
                  © Powered by Nok Soft Co .,Ltd .
                </p>
              </div>
            </div>

            {/* <div className="col-md-6 text-center text-md-right">
              <div className="footer-right mb-5">
                <p className="mb-0 font-size-3 text-stone">
                  {" "}
                  Built with love by <Link to="/#">Grayic</Link>
                </p>
              </div>
  </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTrans(Footer)