export const menuItems = [
         {
           name: "process",
           label: "Process",
         },
         {
           name: "customer",
           label: "Customers",
         },
         {
           name: "pricing",
           label: "Pricing",
         },
       ];
